import React, { useState, useEffect } from 'react';

const ApxEditAddress = (props) => {

    const blank_address = {
        "acctId": "",
        "name": "",
        "org": "",
        "address_1": "",
        "address_2": "",
        "city": "",
        "state": "",
        "zip": "",
        "default": false
    };
    const [address, set_address] = useState({...blank_address});
    const [type, set_type] = useState("new");

    useEffect(() => {
        if (props.data === null) {
            set_address({...blank_address});
            set_type("new");
        } else {
            set_address({...props.data});
            set_type("edit");
        }
    }, [props.data]) 

    function onClickOkay() {
        if (type === "new") {
            props.onNew( address );
        } else {
            props.onEdit( address ); 
        }
        set_address({...blank_address});
    }

    function onClickCancel() {
        if (type ==="new") {
            set_address({...blank_address});
        } else {
            set_address({...props.data});
        }
        props.onCancel();
    }

    function onChangeAddrInfo(addr, field) {
        return (args) => {
            addr[field] = args.target.value;
            set_address({...addr});
        }
    }

    function onChangeDefaultAddr(args) {
        address.default = args.target.checked;
        set_address({...address});
    }

    function onTest(args) {
        console.log(address);
    }

    if(address === null) {
        return null;
    }

    return(
        <div className={"fixed z-10 inset-0 overflow-y-auto " + ((props.show === true) ? "visible" : "hidden")}>
            <div className={"flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"}>
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-50"></div>
                </div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <div className={"inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"} role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    <div className={"flex w-full font-medium text-gray-700 border-b-2 justify-start p-4"}>
                        New Address
                    </div>
                    <div className={"p-4 space-y-2"}>
                        <div className={"w-full"}>
                            <label>Recipient Name 
                                <input className="standardInput" type="text" value={address.name} onChange={onChangeAddrInfo(address, "name")} />
                            </label>
                        </div>
                        <div className={"w-full"}>
                            <label>Organization 
                                <input className="standardInput" type="text" value={address.org} onChange={onChangeAddrInfo(address, "org")} />
                            </label>
                        </div>
                        <div className={"w-full"}>
                            <label>Address 1<span className="text-red-600"> *</span>
                                <input className="standardInput" type="text" value={address.address_1} required onChange={onChangeAddrInfo(address, "address_1")} />
                            </label>
                        </div>
                        <div className={"w-full"}>
                            <label>Address 2
                                <input className="standardInput" type="text" value={address.address_2} onChange={onChangeAddrInfo(address, "address_2")} />
                            </label>
                        </div>
                        <div className={"flex flex-row justify-start items-start w-full space-x-8"}>
                            <div className="w-full">
                                <label>City<span className="text-red-600"> *</span>
                                    <input className="standardInput" type="text" value={address.city} required onChange={onChangeAddrInfo(address, "city")} />
                                </label>
                            </div>
                            <div className="w-full">
                                <label>State<span className="text-red-600"> *</span>
                                    <input className="standardInput" type="text" value={address.state} maxlength="2" required onChange={onChangeAddrInfo(address, "state")} />
                                </label>
                            </div>
                            <div className="w-full">
                                <label>Zip Code<span className="text-red-600"> *</span>
                                    <input className="standardInput" type="text" value={address.zip} required onChange={onChangeAddrInfo(address, "zip")} />
                                </label>
                            </div>
                        </div>
                        <div className="w-full pt-2">
                            <label>Default Address?&emsp;&ensp;
                                <input className="relative -bottom-0.5" type="checkbox" checked={address.default} onChange={onChangeDefaultAddr} />
                            </label>
                        </div>
                    </div>
                    <div>
                        <button onClick={onTest}>Testing</button>
                    </div>
                    <div className="bg-gray-100 px-5 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                        <button type="button" onClick={onClickOkay} className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-350 text-base font-medium text-white hover:bg-green-375 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm">
                            Okay
                        </button>
                        <button type="button" onClick={onClickCancel} className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-500 opacity-80 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ApxEditAddress;
