import React, {useContext, useState, useEffect} from 'react';
import Dashview from 'paths/admin/dashboard/Dashview';
import axios from 'axios';
import {AppContext} from 'AppContext';
import {useParams} from "react-router-dom";
import ApxEditAddress from "comps/ApxEditAddress";


const Content = (props) => {
    // Need the account id from the URL parameters:
    const params = useParams();
    // Need the user's identity and global application config:
    const { config } = useContext(AppContext);

    const [account, set_account] = useState(null);
    const [pricing, set_pricing] = useState([]);
    const [addresses, set_addresses] = useState(null);
    const [show_new_addr_modal, set_show_new_addr_modal] = useState(false);
    const [modal_addr, set_modal_addr] = useState(null);
    
    // Retrieve relevant records for this account:
    useEffect(() => {
        const action = async () => {
            const account_api_response = await axios.get(config.api.base + '/admin/accounts/' + params.id);
            set_account(account_api_response.data[0]);
            const pricing_api_response = await axios.get(config.api.base + '/pricing/tables');
            set_pricing(pricing_api_response.data);
            const addresses_api_response = await axios.get(config.api.base + '/addresses/account/' + params.id);
            console.log(addresses_api_response.data);
            set_addresses(addresses_api_response.data);
        }
        action();
    }, [config.api.base, params.id])

    function onChangeFirstName(args) {
        account.first_name = args.target.value;
        set_account({...account})
    }

    function onChangeLastName(args) {
        account.last_name = args.target.value;
        set_account({...account})
    }

    function onChangeCompany(args) {
        account.company = args.target.value;
        set_account({ ...account})
    }

    function onChangeEmail(args) {
        account.email = args.target.value;
        set_account({...account})
    }

    function onChangePhone(args) {
        account.phone = args.target.value;
        set_account({...account})
    }

    function onChangePriceTable(args) {
        account.pricing_id = args.target.value;
        set_account({ ...account})
    }

    function onDeleteAddr(selected_addr) {
        return (args) => {
            const action = async () => {
                try {
                    if (addresses[selected_addr].default && addresses.length > 1) {
                        const new_default = selected_addr === 0 ? 1 : 0;
                        await axios.put(config.api.base + '/addresses/' + addresses[new_default].id, {...addresses[new_default], default: true})
                    }
                    
                    const response = await axios.delete(config.api.base + '/addresses/' + addresses[selected_addr].id);
                    console.log(response);

                    const action = async () => {
                        const address_api_response = await axios.get(config.api.base + '/addresses/account/' + params.id);
                        set_addresses(address_api_response.data);
                        console.log(address_api_response.data);
                    }
                    action();
                } catch (e) {}
            }
            action()
        }
    }

    
    function onEditAddr(selected_addr) {
        return (args) => {
            set_modal_addr(addresses[selected_addr]);
            set_show_new_addr_modal(true);
        }
    }
    
    function onAddNewAddr(_) {
        set_modal_addr(null);
        set_show_new_addr_modal(true);
    }

    function onCancelNewAddr() {
        set_show_new_addr_modal(false);
    }
    
    function onSaveNewAddr(new_addr) {
        const action = async () => {
            try {
                new_addr.acctId = params.id;
                console.log(new_addr);

                if (new_addr.default) { 
                    const old_default_addr_index = addresses.findIndex((addr) => addr.default);
                    if (old_default_addr_index !== -1) {
                        const old_default_addr = { ...addresses[old_default_addr_index], default: false};
                        await axios.put(config.api.base + '/addresses/' + old_default_addr.id, old_default_addr);
                    }
                }

                const response = await axios.post(config.api.base + '/addresses', new_addr)
                console.log(response);

                const action = async () => {
                    const address_api_response = await axios.get(config.api.base + '/addresses/account/' + params.id);
                    set_addresses(address_api_response.data);
                    console.log(address_api_response.data);
                }
                action();

            } catch (e) {}
        }
        action();
        set_show_new_addr_modal(false);
    }

    function onSaveEditAddr(edited_addr) {
        const action = async () => {
            try {
                if (edited_addr.default) { 
                    const old_default_addr_index = addresses.findIndex((addr) => addr.default);
                    if (old_default_addr_index !== -1) {
                        const old_default_addr = { ...addresses[old_default_addr_index], default: false};
                        await axios.put(config.api.base + '/addresses/' + old_default_addr.id, old_default_addr);
                    }
                }

                const response = await axios.put(config.api.base + '/addresses/' + edited_addr.id, edited_addr)
                console.log(response);

                const action = async () => {
                    const address_api_response = await axios.get(config.api.base + '/addresses/account/' + params.id);
                    set_addresses(address_api_response.data);
                    console.log(address_api_response.data);
                }
                action();

            } catch (e) {}
        }
        action();
        set_show_new_addr_modal(false);
    }

    function onSubmit(args) {
        args.preventDefault();
        const action = async () => {
            try {
                const response = await axios.put(config.api.base + '/admin/accounts/' + params.id, account);
                console.log(response);
            } catch (x) {

            }
        }
        action();
    }

    if(account === null || addresses === null) {
        return null;
    }

    return(
        <div className={"container sm:w-4/5 mx-auto mt-8"}>
            <div className="w-full space-y-8">
                <div>
                    <h2 className={"mt-6 text-3xl font-extrabold text-gray-900"}>
                        Edit Account:&nbsp;
                        <span className={"font-normal text-3xl text-gray-500"}>
                            {params.id}
                        </span>
                    </h2>
                </div>
                <form className="mt-8 space-y-6" onSubmit={onSubmit}>
                    <div className={"flex"}>
                    <div className={"w-2/4 border-r-2 pr-4 space-y-4 border-dashed"}>
                        <div className={"w-full"}>
                            <h3 className="text-xl font-semibold text-gray-900">User Information</h3>
                        </div>
                        <div className={"flex space-x-6"}>
                            <div className={"w-full"}>
                                <label htmlFor="first-name">First name</label>
                                <input id="first-name" name="firstName" error={"true"}  type="text" autoComplete="given-name"
                                    value={account.first_name} required
                                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                    placeholder="First name"
                                    onChange={onChangeFirstName}
                                />
                            </div>
                            <div className={"w-full"}>
                                <label htmlFor="last-name">Last name</label>
                                <input id="last-name" name="lastName" type="text" autoComplete="family-name" required
                                    value={account.last_name}
                                    onChange={onChangeLastName}
                                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                    placeholder="Last name"/>
                            </div>
                        </div>
                        <div>
                            <label htmlFor="company">Company</label>
                            <input id="company" name="company" type="text" autoComplete="company"
                                required
                                value={account.company}
                                onChange={onChangeCompany}
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                placeholder="Company"/>
                        </div>
                        <div>
                            <label htmlFor="email">Email address</label>
                            <input id="email" name="email" type="email" autoComplete="email"
                                required
                                value={account.email}
                                onChange={onChangeEmail}
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                placeholder="Email"/>
                        </div>
                        <div>
                            <label htmlFor="phone">Phone number</label>
                            <input id="phone" name="email" type="text" autoComplete="phone"
                                required
                                value={account.phone}
                                onChange={onChangePhone}
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                placeholder="Phone number"/>
                        </div>
                        <div className={"w-full"}>
                            <div>
                                <label htmlFor="price_table" className="block text-sm font-medium text-gray-700">
                                    Pricing Table:
                                </label>
                                <select
                                    id="price_table"
                                    name="price_table"
                                    autoComplete="price_table"
                                    value={account.pricing_id}
                                    onChange={onChangePriceTable}
                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                >
                                    {pricing.map((item, index) => {
                                        return <option key={index} value={item.id}>{item.name}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className={" w-2/4 space-y-4 pl-4"}>
                        <div className={"w-full"}>
                            <h3 className="text-xl font-semibold text-gray-900">Address Information</h3>
                        </div>
                        {addresses.length > 0 ?
                            addresses.map((item, index) => {
                                const default_line = item.default ? "[Default]\n" : "";
                                const city_state_line = item.city + ", " + item.state + " " + item.zip;
                                const address_block = [item.name, item.org, item.address_1, item.address_2, city_state_line].filter(Boolean).join("\n");
                                return <div className={"w-full flex items-center justify-between border-b pb-2 border-dashed border-gray-500"}>
                                    <div className={"whitespace-pre"}>
                                        <span className="font-medium">{default_line}</span>{address_block}
                                    </div>
                                    <div className={"flex items-center space-x-2"}>
                                        <div>
                                            <button type="button" onClick={onEditAddr(index)} className="justify-center rounded-md border border-transparent shadow-sm px-2 py-1 text-base text-gray-800 bg-gray-300 hover:bg-gray-400  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 ">
                                                Edit Address
                                            </button>
                                        </div>
                                        <div>
                                            <button type="button" onClick={onDeleteAddr(index)} className="justify-center rounded-md border border-transparent shadow-sm px-2 py-1 bg-red-500 opacity-80 text-base text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 ">
                                                Delete Address
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            })
                            :
                            <div className={"w-full"}>No address information found. Please add a new address.</div>
                        }
                        <div className={"w-full"}>
                            <div>
                                <button type="button" className="standardButton ml-2 whitespace-nowrap" onClick={onAddNewAddr}>
                                    Add New Address
                                </button>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className={"w-2/4"}>
                        <button type={"submit"} className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            SAVE
                        </button>
                    </div>
                </form>
            </div>
            <ApxEditAddress show={show_new_addr_modal} data={modal_addr} onNew={onSaveNewAddr} onEdit={onSaveEditAddr} onCancel={onCancelNewAddr}></ApxEditAddress>
        </div>
    )
}

const Editor = (props) => {
    return (<Dashview {...props}><Content {...props}/></Dashview>);
}

export default Editor;