import React, {useState, useContext, useEffect} from "react";
import Dashview from "../Dashview";
import axios from 'axios';
import {AppContext} from "AppContext";
import ApxEditAddress from "comps/ApxEditAddress";

const Content = (props) => {
    
    const { identity, config } = useContext(AppContext)
    const [account, set_account] = useState(null);
    const [addresses, set_addresses] = useState(null);
    const [show_new_addr_modal, set_show_new_addr_modal] = useState(false);
    const [modal_addr, set_modal_addr] = useState(null);

    // Retrieve relevant records for this account:
    useEffect(() => {
        const action = async () => {
            const account_api_response = await axios.get(config.api.base + '/admin/accounts/' + identity.userAcctId);
            set_account(account_api_response.data[0]);
            const addresses_api_response = await axios.get(config.api.base + '/addresses/account/' + identity.userAcctId);
            console.log(addresses_api_response.data);
            set_addresses(addresses_api_response.data);
        }
        action();
    }, [config.api.base, identity.userAcctId])

    function onChangeFirstName(args) {
        account.first_name = args.target.value;
        set_account({...account})
    }

    function onChangeLastName(args) {
        account.last_name = args.target.value;
        set_account({...account})
    }

    function onChangeCompany(args) {
        account.company = args.target.value;
        set_account({ ...account})
    }

    function onChangePhone(args) {
        account.phone = args.target.value;
        set_account({...account})
    }

    function onDeleteAddr(selected_addr) {
        return (args) => {
            const action = async () => {
                try {
                    if (addresses[selected_addr].default && addresses.length > 1) {
                        const new_default = selected_addr === 0 ? 1 : 0;
                        await axios.put(config.api.base + '/addresses/' + addresses[new_default].id, {...addresses[new_default], default: true})
                    }
                    
                    const response = await axios.delete(config.api.base + '/addresses/' + addresses[selected_addr].id);
                    console.log(response);

                    const action = async () => {
                        const address_api_response = await axios.get(config.api.base + '/addresses/account/' + identity.userAcctId);
                        set_addresses(address_api_response.data);
                        console.log(address_api_response.data);
                    }
                    action();
                } catch (e) {}
            }
            action()
        }
    }

    
    function onEditAddr(selected_addr) {
        return (args) => {
            set_modal_addr(addresses[selected_addr]);
            set_show_new_addr_modal(true);
        }
    }
    
    function onAddNewAddr(_) {
        set_modal_addr(null);
        set_show_new_addr_modal(true);
    }

    function onCancelNewAddr() {
        set_show_new_addr_modal(false);
    }
    
    function onSaveNewAddr(new_addr) {
        const action = async () => {
            try {
                new_addr.acctId = identity.userAcctId;
                console.log(new_addr);

                if (new_addr.default) { 
                    const old_default_addr_index = addresses.findIndex((addr) => addr.default);
                    if (old_default_addr_index !== -1) {
                        const old_default_addr = { ...addresses[old_default_addr_index], default: false};
                        await axios.put(config.api.base + '/addresses/' + old_default_addr.id, old_default_addr);
                    }
                }

                const response = await axios.post(config.api.base + '/addresses', new_addr)
                console.log(response);

                const action = async () => {
                    const address_api_response = await axios.get(config.api.base + '/addresses/account/' + identity.userAcctId);
                    set_addresses(address_api_response.data);
                    console.log(address_api_response.data);
                }
                action();

            } catch (e) {}
        }
        action();
        set_show_new_addr_modal(false);
    }

    function onSaveEditAddr(edited_addr) {
        const action = async () => {
            try {
                if (edited_addr.default) { 
                    const old_default_addr_index = addresses.findIndex((addr) => addr.default);
                    if (old_default_addr_index !== -1) {
                        const old_default_addr = { ...addresses[old_default_addr_index], default: false};
                        await axios.put(config.api.base + '/addresses/' + old_default_addr.id, old_default_addr);
                    }
                }

                const response = await axios.put(config.api.base + '/addresses/' + edited_addr.id, edited_addr)
                console.log(response);

                const action = async () => {
                    const address_api_response = await axios.get(config.api.base + '/addresses/account/' + identity.userAcctId);
                    set_addresses(address_api_response.data);
                    console.log(address_api_response.data);
                }
                action();

            } catch (e) {}
        }
        action();
        set_show_new_addr_modal(false);
    }

    function onSubmit(args) {
        args.preventDefault();
        const action = async () => {
            try {
                const response = await axios.put(config.api.base + '/admin/accounts/' + identity.userAcctId, account);
                console.log(response);
            } catch (x) {

            }
        }
        action();
    }

    function on_click_testing(args) {
        console.log(identity);
        console.log(account);
    }

    if (account === null || addresses === null) {
        return null;
    }
    
    return (
        <div className={"container mx-auto"}>
            <div className={"border-b-2 border-solid py-4"}>
                <div>
                    <span className={"text-3xl"}>Account Information</span>
                </div>
            </div>
            { true && // pr-4 border-r-2 border-dashed
            <div>
                <button onClick={on_click_testing}>Testing</button>
            </div>
            }
            <form className="mt-8" onSubmit={onSubmit}>
                <div className="flex">
                    <div className="flex flex-col justify-start items-start w-2/4 space-y-4 border-r-2 pr-4 border-dashed">
                        <div className={"flex w-full"}>
                            <div className={"w-full"}>
                                <label htmlFor="email">Email / Username</label>
                                <input id="email" name="email" className="standardInput" type="text" placeholder="Company" 
                                        value={account.email} disabled 
                                />
                            </div>
                        </div>
                        <div className={"flex w-full space-x-8"}>
                            <div className={"w-full"}>
                                <label htmlFor="first-name">First name</label>
                                <input id={"first-name"} name="firstName" className="standardInput" type="text" placeholder="First Name" 
                                        value={account.first_name} onChange={onChangeFirstName}     
                                />
                            </div>
                            <div className={"w-full"}>
                                <label htmlFor="last-name">Last name</label>
                                <input id={"last-name"} name="lastName" className="standardInput" type="text" placeholder="Last Name" 
                                        value={account.last_name} onChange={onChangeLastName}
                                />
                            </div>
                        </div>
                        <div className={"flex w-full"}>
                            <div className={"w-full"}>
                                <label htmlFor="company">Company</label>
                                <input id="company" name="company" className="standardInput" type="text" placeholder="Company" 
                                        value={account.company} onChange={onChangeCompany}     
                                />
                            </div>
                        </div>
                        <div className={"flex w-full"}>
                            <div className={"w-full"}>
                                <label htmlFor="phone">Phone Number</label>
                                <input id="phone" name="phone" className="standardInput" type="text" placeholder="Phone Number" 
                                        value={account.phone} onChange={onChangePhone}     
                                />
                            </div>
                        </div>
                        <div className={"flex w-full pt-4"}>
                            <button type={"submit"} className="mr-4 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                SAVE 
                            </button>
                        </div>
                    </div>
                    <div className="w-2/4 space-y-4 pl-4">
                        <div className={"w-full"}>
                            <h3 className="text-xl font-semibold text-gray-900">Address Information</h3>
                        </div>
                        {addresses.length > 0 ?
                            addresses.map((item, index) => {
                                const default_line = item.default ? "[Default]\n" : "";
                                const city_state_line = item.city + ", " + item.state + " " + item.zip;
                                const address_block = [item.name, item.org, item.address_1, item.address_2, city_state_line].filter(Boolean).join("\n");
                                return <div className={"w-full flex items-center justify-between border-b pb-2 border-dashed border-gray-500"}>
                                    <div className={"whitespace-pre"}>
                                        <span className="font-medium">{default_line}</span>{address_block}
                                    </div>
                                    <div className={"flex items-center space-x-2"}>
                                        <div>
                                            <button type="button" onClick={onEditAddr(index)} className="justify-center rounded-md border border-transparent shadow-sm px-2 py-1 text-base text-gray-800 bg-gray-300 hover:bg-gray-400  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 ">
                                                Edit Address
                                            </button>
                                        </div>
                                        <div>
                                            <button type="button" onClick={onDeleteAddr(index)} className="justify-center rounded-md border border-transparent shadow-sm px-2 py-1 bg-red-500 opacity-80 text-base text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 ">
                                                Delete Address
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            })
                            :
                            <div className={"w-full"}>No address information found. Please add a new address.</div>
                        }
                        <div className={"w-full"}>
                            <div>
                                <button type="button" className="standardButton ml-2 whitespace-nowrap" onClick={onAddNewAddr}>
                                    Add New Address
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </form>
            <ApxEditAddress show={show_new_addr_modal} data={modal_addr} onNew={onSaveNewAddr} onEdit={onSaveEditAddr} onCancel={onCancelNewAddr}></ApxEditAddress>
        </div>
    )
}

const Account = (props) => {
    return (<Dashview {...props}><Content/></Dashview>);
}

export default Account;