import React, { useContext, useState } from "react";
import { AppContext } from 'AppContext';
import axios from 'axios';
import { useHistory, NavLink, Route } from 'react-router-dom';

const AppRoute = ({ component: Component, ...rest}) => {

    const { identity, setIdentity, config } = useContext(AppContext);
    const history = useHistory();

    function onLogout() {
        const action = async () => {
            try {
                const response = await axios.delete(config.api.base + '/ident')
            } catch(x) {
                console.log("An error occurred: " + x.message)
            }
            setIdentity(null);
        }
        action();
    }

    function on_clicked_sign_up() {
        history.push('/sign-up')
    }

    function on_clicked_sign_in() {
        history.push('/dashboard')
    }

    function onLogout() {
        const action = async () => {
            try {
                const response = await axios.delete(config.api.base + '/ident')
            } catch(x) {
                console.log("An error occurred: " + x.message)
            }
            setIdentity(null);
            history.push('/sign-in');

        }
        action();
    }

    function onDashboard() {
        if(identity.userAdmin === true) {
            history.push('/admin/dashboard')
        } else {
            history.push('/dashboard')
        }
    }

    if(identity) {
        return (
            <div className={"font-sans"}>
                <nav className={"bg-white shadow-md z-10 text-white w-full fixed bg-green-200"}>
                    <div className={'max-w-full h-20'}>
                        <div className="flex items-center justify-between h-full">
                            <div className="flex items-center pl-10">
                                <div className="flex-shrink-0 border-solid">
                                    <img width={230} height={52} src={"/logo.png"} alt="Logo for AbCorp 3D"/>
                                </div>
                            </div>
                            <div className={"flex flex-grow flex-row-reverse"}>
                                <div className={"flex mr-10 space-x-4"}>
                                    <div className={"text-black"}>
                                        <button onClick={onLogout}>Logout</button>
                                    </div>
                                </div>
                                <div className={"flex mr-10 space-x-4"}>
                                    <div className={"text-black"}>
                                        <button onClick={onDashboard}>Test Dashboard</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
                <Route component={Component} {...rest}/>
            </div>
        )
    } else {
        return (
            <div className={"font-sans "} >
                <nav className={"bg-white shadow-md z-10 text-white w-full fixed bg-green-200"}>
                    <div className={'max-w-full h-20'}>
                        <div className="flex items-center justify-between h-full">
                            <div className="flex items-center pl-10">
                                <div className="flex-shrink-0 border-solid">
                                    <img width={230} height={52} src={"/logo.png"} alt="Logo for ABCorp 3D"/>
                                </div>
                            </div>
                            <div className={"flex flex-grow flex-row-reverse"}>
                                <div className={"flex mr-10 space-x-4"}>
                                    <div className={"text-black"}>
                                        <NavLink to={'/sign-up'}>Sign Up</NavLink>
                                    </div>
                                    <div className={"text-black"}>
                                        <NavLink to={'/sign-in'}>Login</NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
                <Route component={Component} {...rest}/>
            </div>
        )
    }
};

export default AppRoute;
